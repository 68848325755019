import { removeAllCookies } from "../../../lib/cookies";

const reloadPageWithQuery = (queryToRemove: string[] = []) => {
  const searchParameters = new URLSearchParams(window.location.search);
  queryToRemove.forEach((query) => {
    if (searchParameters.has(query)) {
      searchParameters.delete(query);
    }
  });
  window.location.assign(`/?${searchParameters.toString()}`);
};

export default function resetKiosk(): void {
  removeAllCookies();
  reloadPageWithQuery(["plan_id"]);
}
