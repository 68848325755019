import { User } from "../../apiTypes";
import { CookieKeys, getCookie } from "../../../lib/cookies";

// eslint-disable-next-line react-func/max-lines-per-function
const getInitialPlanData = async (user?: User) => {
  const url = new URL(document.location.href);
  if (url.searchParams.get("kiosk") === "true") {
    return {
      planId: null,
    };
  }

  // a user is logged in and already has a plan
  if (user?.plan?.id) {
    return {
      planId: user.plan.id,
    };
  }

  // a plan from the session api, eg: a customer interacting with the VW widget button

  if (!!url.searchParams.get("planId")) {
    const planId = url.searchParams.get("planId");

    // remove these parameters so if the user copies the browser url they aren't sending these
    // to someone else
    url.searchParams.delete("planId");
    history.replaceState({}, "", `?${url.searchParams.toString()}`);

    return {
      planId: planId,
    };
  }

  // plan from a previous session
  const planIdCookie = await getCookie<number | undefined>(
    CookieKeys.planId,
    undefined,
    Number,
  );

  if (!!planIdCookie) {
    return {
      planId: planIdCookie,
    };
  }

  // potentially a plan from a session cookie, if the user hasn't interacted with VW then this
  // could be null
  return {
    planId: VisitWidget.store.currentPlanId,
  };
};

export { getInitialPlanData };
