import * as constants from "../constants";

export default function getCustomStyles(): Record<string, unknown> {
  return {
    container: (styles) => ({
      ...styles,
      color: constants.white,
      flex: 1,
    }),
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: VisitWidget.settings.mobile_menu_background_color,
      borderRadius: "5px",
      top: "30px",
      width: 220,
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: window.innerHeight * 0.7,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      ...(isSelected
        ? { backgroundColor: VisitWidget.settings.currentClientPrimaryColor }
        : {}),
    }),
    placeholder: (styles) => ({
      ...styles,
      color: constants.white,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: constants.white,
    }),
    valueContainer: (styles) => ({
      ...styles,
      color: constants.white,
      fontWeight: 700,
      fontSize: "11px",
      paddingLeft: "0px",
      paddingRight: "0px",
    }),
  };
}
